<template>
  <div class="nick-name w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('編輯名字')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :right-text="$t('完成')"
      @on-click-right="onSubmit"
    ></base-header>
    <div class="bg-white w-100 h-100 p-3">
      <b-form @submit.prevent="onSubmit">
        <b-form-group label-for="nickName" class="nickName-input">
          <b-form-input
            id="nickName"
            ref="nickName"
            v-model="$v.formData.nickName.$model"
            name="nickName"
            aria-describedby="input-nickName-feedback"
            type="text"
            :placeholder="userData.nickName"
            :state="formData.isNickNameValid"
            @input="nickNameInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-nickName-feedback"
            :state="formData.isNickNameValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.nickName.required" class="error">
              {{ $t('*請輸入暱稱') }}
            </div>
            <div v-else-if="backendError">{{ backendError }}</div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import {required} from 'vuelidate/lib/validators';
import userService from '@/services/user';

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      formData: {
        nickName: '',
        isNickNameValid: null,
      },
      backendError: '',
    };
  },
  validations: {
    formData: {
      nickName: {required},
    },
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      this.formData.isNickNameValid = null;
      if (this.$v.$invalid) {
        this.formData.isNickNameValid = false;
        this.$refs.nickName.focus();
      } else {
        await this.submitUpdate();
      }
    },
    nickNameInputHandler() {
      this.formData.isNickNameValid = this.formData.nickName ? null : false;
      this.backendError = '';
    },
    async submitUpdate() {
      try {
        this.$store.commit('env/setIsLoading', true);

        await userService.updateUser({
          nickName: this.formData.nickName,
          isActive: true,
        });
        await this.$store.dispatch('user/loginWithJwt');
        return this.$router.back();
      } catch (error) {
        this.formData.isNickNameValid = false;
        if (error.response.data.message === 'Incorrect nickName!') {
          this.backendError = '*' + this.$t('該名稱不得使用');
        } else {
          this.backendError = '*' + this.$t('個人資訊更改失敗');
        }
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nick-name {
  .error {
    margin-top: 4px;
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
  }
  .form-control {
    font-size: 14px;
  }
}
</style>
